import {
  CandidateDashboardViewsSecondaryText,
  CandidateDashboardViewsPrimaryText,
} from './utils/styles';
import { DashboardViewsProps } from './utils/types';
import { PrimaryButton } from 'sharedComponents/Buttons';
import { styled } from '@mui/material';
import { Box } from '@mui/material';
import { ButtonStyle } from 'features/Connect/components/Buttons/styles';
import { useStateParam } from 'features/Connect/features/Context/ConnectStateCodeContextProvider';

export const CandidateDashboardViews = ({ viewState }: DashboardViewsProps) => {
  const Icon = viewState.icon;
  const jobboardState = useStateParam();

  const handleButtonAction = () => {
    if (viewState.buttonAction) {
      if (viewState.requiresStateParam) {
        viewState.buttonAction(jobboardState);
      } else {
        viewState.buttonAction();
      }
    }
  };

  return (
    <CandidateDashboardViewsContainer>
      <Icon />
      <CandidateDashboardViewsPrimaryText>
        {viewState.primaryText}
      </CandidateDashboardViewsPrimaryText>
      <CandidateDashboardViewsSecondaryText>
        {viewState.secondaryText}
      </CandidateDashboardViewsSecondaryText>
      {viewState.buttonAction && (
        <PrimaryButton
          color="primary"
          sx={ButtonStyle}
          startIcon={viewState.buttonStartIcon}
          endIcon={viewState.buttonEndIcon}
          onClick={handleButtonAction}
        >
          {viewState.buttonText}
        </PrimaryButton>
      )}
    </CandidateDashboardViewsContainer>
  );
};

const CandidateDashboardViewsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '50vh',
  margin: 'auto',
  gap: theme.spacing(2),
  width: '100%',
  maxWidth: '600px',
  padding: theme.spacing(3),
  textAlign: 'center',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(2),
  },
}));

import { Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PreferenceMatchBadge } from '../InfoBadges/PreferenceMatchBadge';
import { formatDistance, getGradeRange } from 'features/Connect/features/JobDiscovery/utils';
import { ConnectPreferenceMatchesSectionInterface } from 'types/connectTypes';
import { Tooltip } from 'sharedComponents/Tooltip';

export const PreferenceMatchesSection: React.FC<ConnectPreferenceMatchesSectionInterface> = ({
  school,
  preferenceMatches,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const maxSubjects = isTablet ? 2 : 3;

  const distanceMatchLabel = `${formatDistance(school.distance_from_search_location_miles)} miles away`;

  const preferenceMatchList: (string | React.ReactElement)[] = [];
  const gradesMatchedTotal = preferenceMatches?.grades_matched?.length;
  const subcategoriesMatchedTotal = preferenceMatches?.subcategories_matched?.length;
  const hasDistancePreferenceMatch = preferenceMatches?.has_distance_preference_match;

  if (gradesMatchedTotal > 0) {
    const gradeMatchLabel =
      (gradesMatchedTotal > 1 ? 'Grades' : 'Grade') +
      ' ' +
      getGradeRange(preferenceMatches.grades_matched);
    preferenceMatchList.push(gradeMatchLabel);
  }

  if (subcategoriesMatchedTotal > 0) {
    const firstSubjects = preferenceMatches.subcategories_matched.slice(0, maxSubjects);

    if (subcategoriesMatchedTotal > maxSubjects) {
      const remainingCount = subcategoriesMatchedTotal - maxSubjects;
      const tooltipContent = (
        <Stack>
          {preferenceMatches.subcategories_matched.map((subject) => (
            <Typography key={subject}>{subject}</Typography>
          ))}
        </Stack>
      );
      preferenceMatchList.push(
        <Tooltip title={tooltipContent} placement="top" sx={tooltipStyles}>
          <span>{`${firstSubjects.join(', ')} + ${remainingCount} more`}</span>
        </Tooltip>
      );
    } else {
      preferenceMatchList.push(firstSubjects.join(', '));
    }
  }

  if (hasDistancePreferenceMatch) {
    preferenceMatchList.push(distanceMatchLabel);
  }

  return (
    <PreferenceMatchesSectionContainer>
      <PreferenceMatchesSectionTitle>What you matched on:</PreferenceMatchesSectionTitle>
      <PreferenceMatchesBadgeContainer>
        {preferenceMatchList.map((match, index) => (
          <PreferenceMatchBadge
            key={typeof match === 'string' ? match : `match-${index}`}
            preferenceMatch={typeof match === 'string' ? match : match.props.children}
          />
        ))}
      </PreferenceMatchesBadgeContainer>
    </PreferenceMatchesSectionContainer>
  );
};

const PreferenceMatchesSectionContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const PreferenceMatchesSectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.tertiary,
  fontSize: theme.typography.subtitle.fontSize,
  fontStyle: 'normal',
  fontWeight: theme.typography.fontWeightMedium,
  lineHeight: theme.typography.h2.lineHeight,
}));

const PreferenceMatchesBadgeContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    '& > *': {
      width: '100%',
    },
  },
}));

const tooltipStyles = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'white.main',
    color: 'text.primary',
    border: '1px solid',
    borderColor: 'gray.light',
    borderRadius: '8px',
    boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    p: 1.5,
    '& .MuiTypography-root': {
      fontSize: 'body2.fontSize',
      py: 0.5,
      px: 0,
    },
  },
};

import { useTheme } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { SecondaryButton } from 'sharedComponents/Buttons';
import { PaginationWithArrowsProps } from './types';
import {
  PaginationButtonGroupContainer,
  PaginationButtonIconStyles,
  PaginationButtonStyles,
  PaginationLocationText,
  PaginationLocationTextContainer,
} from './styles';
import { useConnectContext } from 'features/Connect/features/Context/ConnectContextProvider';
import { handlePrevPage, handleNextPage, isPaginationButtonDisabled } from './utils';

export const MobilePaginationButtonGroup: React.FC<PaginationWithArrowsProps> = ({
  totalPages,
  displayTotal,
  currentPage,
  onChange,
  dataTestId,
  isDisabled,
  shouldHideLocationText,
  isEndOfMatches,
}) => {
  const { isLoading } = useConnectContext();
  const theme = useTheme();

  const isPrevButtonDisabled = isPaginationButtonDisabled(
    'prev',
    currentPage,
    totalPages,
    isDisabled,
    isLoading
  );
  const isNextButtonDisabled = isPaginationButtonDisabled(
    'next',
    currentPage,
    totalPages,
    isDisabled,
    isLoading
  );

  return (
    <PaginationButtonGroupContainer data-testid={dataTestId} theme={theme}>
      <SecondaryButton
        onClick={() => handlePrevPage(currentPage, onChange)}
        disabled={isPrevButtonDisabled}
        dataTestId={`${dataTestId}-prev-button`}
        sx={PaginationButtonStyles(theme, isPrevButtonDisabled)}
      >
        <ArrowBackIosNewIcon sx={PaginationButtonIconStyles(theme, isPrevButtonDisabled)} />
      </SecondaryButton>
      {!shouldHideLocationText && !isEndOfMatches && (
        <PaginationLocationTextContainer>
          <PaginationLocationText data-testid={`${dataTestId}-page-number`}>
            Match {currentPage}
          </PaginationLocationText>
          <PaginationLocationText>of</PaginationLocationText>
          <PaginationLocationText data-testid={`${dataTestId}-total-pages-number`}>
            {displayTotal}
          </PaginationLocationText>
        </PaginationLocationTextContainer>
      )}
      <SecondaryButton
        onClick={() => handleNextPage(currentPage, totalPages, onChange)}
        disabled={isNextButtonDisabled}
        dataTestId={`${dataTestId}-next-button`}
        sx={PaginationButtonStyles(theme, isNextButtonDisabled)}
      >
        <ArrowForwardIosIcon sx={PaginationButtonIconStyles(theme, isNextButtonDisabled)} />
      </SecondaryButton>
    </PaginationButtonGroupContainer>
  );
};

import { Box, Skeleton, styled } from '@mui/material';

export const CandidateMatchCardSkeleton = () => {
  return (
    <CardSkeletonContainer>
      <CardContent>
        {/* Card Title */}
        <TitleSkeleton variant="text" width={250} />

        {/* Principal Message Section */}
        <MessageSection>
          <PrincipalInfoContainer>
            <CircularSkeleton variant="circular" width={48} height={48} />
            <Box sx={{ flex: 1 }}>
              <TextSkeleton variant="text" width={200} height="h3" sx={{ mb: 1 }} />
              <TextSkeleton variant="text" width={160} height="body1" />
            </Box>
          </PrincipalInfoContainer>
          <TextSkeleton variant="text" width="90%" height="body1" />
          <TextSkeleton variant="text" width="85%" height="body1" />
        </MessageSection>

        {/* School Info Section */}
        <SchoolInfoSection>
          <TextSkeleton variant="text" width={180} height="h3" sx={{ mb: 2 }} />
          <HighlightsContainer>
            {[1, 2, 3].map((i) => (
              <RoundedSkeleton key={i} variant="rounded" width={120} height={40} />
            ))}
          </HighlightsContainer>
        </SchoolInfoSection>

        {/* Action Buttons */}
        <ActionButtonsContainer>
          <ActionButtonSkeleton variant="rounded" width={160} height={48} />
          <ActionButtonSkeleton variant="rounded" width={160} height={48} />
        </ActionButtonsContainer>
      </CardContent>
    </CardSkeletonContainer>
  );
};

const CardSkeletonContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));

const CardContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const MessageSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const PrincipalInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const SchoolInfoSection = styled(Box)({
  width: '100%',
});

const HighlightsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const ActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
  justifyContent: 'center',
}));

const TextSkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'height',
})<{ height: 'h2' | 'h3' | 'body1' }>(({ theme, height }) => ({
  bgcolor: theme.palette.gray.light,
  height: theme.typography[height].fontSize,
}));

const TitleSkeleton = styled(Skeleton)(({ theme }) => ({
  bgcolor: theme.palette.gray.light,
  height: theme.typography.h2.fontSize,
  marginBottom: theme.spacing(3),
}));

const CircularSkeleton = styled(Skeleton)(({ theme }) => ({
  bgcolor: theme.palette.gray.light,
}));

const RoundedSkeleton = styled(Skeleton)(({ theme }) => ({
  bgcolor: theme.palette.gray.light,
}));

const ActionButtonSkeleton = styled(Skeleton)(({ theme }) => ({
  bgcolor: theme.palette.gray.light,
}));

/* global process */
import 'babel-polyfill';
import 'bootstrap/dist/css/bootstrap.css';
import 'rc-slider/assets/index.css';
import 'react-datetime/css/react-datetime.css';
import 'react-dropdown/style.css';
// main compiled stylesheet
import './styles.css';

import * as Sentry from '@sentry/browser';
import 'api'; // Required to set axios global defaults
import App from './App';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isProductionEnvironment } from './utils/util';

Sentry.init({
  dsn: isProductionEnvironment()
    ? process.env.REACT_APP_SENTRY_DSN_PROD
    : process.env.REACT_APP_SENTRY_DSN_STAGING,
  release: process.env.REACT_APP_HEROKU_SLUG_COMMIT,
  whitelistUrls: ['app.hirenimble.com', 'dev.hirenimble.com'],
  ignoreErrors: [
    'Error: Network Error',
    'Error: timeout of 0ms exceeded',
    'Error: Extension context invalidated.',
    'Non-Error promise rejection captured with keys: currentTarget, detail, isTrusted, target',
  ],
});

const queryClient = new QueryClient();

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

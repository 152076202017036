import { BaseNimbleAPI } from 'api/baseAPI';
import { SchoolConnectAction } from './types';

class CandidateSchoolActionsAPI extends BaseNimbleAPI {
  get config() {
    return { baseURL: '/api/platform-match-response-status' };
  }

  async createSchoolConnectAction(action: SchoolConnectAction): Promise<void> {
    await this.post('/create-or-update/', action);
  }
}

export default new CandidateSchoolActionsAPI();

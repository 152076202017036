import { CandidateMatchDashboardState, DashboardModalTypes } from './types';
import { CandidateMatchDashboardActionTypes } from './types';
import { CandidateMatchDashboardActionsEnum } from './constants';

export const initialCandidateMatchDashboardState: CandidateMatchDashboardState = {
  totalPendingMatches: 0,
  currentMatchIndex: 0,
  isEndOfMatches: false,
  hasPendingMatches: true,
  totalPages: 0,
  isLastMatch: false,
  displayTotal: 0,
  modalStates: {
    [DashboardModalTypes.REJECT]: false,
    [DashboardModalTypes.ACCEPT]: false,
    [DashboardModalTypes.ALREADY_CONNECTED]: false,
    [DashboardModalTypes.IN_APP_MESSAGING]: false,
  },
  matchCardVacancies: [],
  actionedMatchIds: [],
};

export const candidateMatchDashboardReducer = (
  state: CandidateMatchDashboardState,
  action: CandidateMatchDashboardActionTypes
): CandidateMatchDashboardState => {
  switch (action.type) {
    case CandidateMatchDashboardActionsEnum.SET_TOTAL_PENDING_MATCHES: {
      const totalPendingMatches = action.payload;
      const totalPages = totalPendingMatches ? totalPendingMatches + 1 : 0;
      return {
        ...state,
        totalPendingMatches,
        totalPages,
        isEndOfMatches: false,
        displayTotal: totalPendingMatches,
      };
    }

    case CandidateMatchDashboardActionsEnum.SET_CURRENT_MATCH_INDEX: {
      const currentMatchIndex = action.payload;
      const isEndOfMatches = currentMatchIndex >= state.totalPendingMatches;
      return {
        ...state,
        currentMatchIndex,
        isEndOfMatches,
      };
    }

    case CandidateMatchDashboardActionsEnum.SET_HAS_PENDING_MATCHES:
      return {
        ...state,
        hasPendingMatches: action.payload,
      };

    case CandidateMatchDashboardActionsEnum.SET_IS_END_OF_MATCHES:
      return {
        ...state,
        isEndOfMatches: action.payload,
      };

    case CandidateMatchDashboardActionsEnum.INITIALIZE_MATCHES: {
      const totalPendingMatches = action.payload.pending.length;
      const totalPages = totalPendingMatches ? totalPendingMatches + 1 : 0;
      return {
        ...state,
        totalPendingMatches,
        totalPages,
        displayTotal: totalPendingMatches,
        hasPendingMatches: totalPendingMatches > 0,
        isEndOfMatches: false,
      };
    }

    case CandidateMatchDashboardActionsEnum.SET_IS_MODAL_OPEN:
      return {
        ...state,
        modalStates: {
          ...state.modalStates,
          [action.modalName]: action.payload,
        },
      };

    case CandidateMatchDashboardActionsEnum.SET_MATCH_CARD_VACANCIES:
      return {
        ...state,
        matchCardVacancies: action.payload,
      };

    case CandidateMatchDashboardActionsEnum.SET_ACTIONED_MATCH_IDS:
      return {
        ...state,
        actionedMatchIds: action.payload,
      };

    default:
      return state;
  }
};

import { Box, Skeleton, styled, useMediaQuery, Theme } from '@mui/material';

export const CandidateMatchDashboardHeaderSkeleton = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <HeaderSkeletonContainer>
      {isMobile ? (
        <MobileSkeletonContainer>
          {/* Mobile Title */}
          <MobileTitleContainer>
            <StyledSkeleton variant="text" width={180} height="h2" />
            <StyledSkeleton variant="text" width={160} height="h3" />
          </MobileTitleContainer>

          {/* Mobile Pagination */}
          <MobilePaginationContainer>
            <RoundedSkeleton variant="rounded" width={40} height={40} />
            <StyledSkeleton variant="text" width={120} height="body1" />
            <RoundedSkeleton variant="rounded" width={40} height={40} />
          </MobilePaginationContainer>
        </MobileSkeletonContainer>
      ) : (
        <DesktopSkeletonContainer>
          <StyledSkeleton variant="text" width={300} height="h2" />
          <ButtonGroupContainer>
            <RoundedSkeleton variant="rounded" width={40} height={40} />
            <RoundedSkeleton variant="rounded" width={40} height={40} />
          </ButtonGroupContainer>
        </DesktopSkeletonContainer>
      )}
    </HeaderSkeletonContainer>
  );
};

const HeaderSkeletonContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 0),
  [theme.breakpoints.down('md')]: {
    textAlign: 'center',
  },
}));

const MobileSkeletonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  alignItems: 'center',
  width: '100%',
}));

const MobileTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const MobilePaginationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(0, 2),
}));

const DesktopSkeletonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
});

const ButtonGroupContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const StyledSkeleton = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== 'height',
})<{ height: 'h2' | 'h3' | 'body1' }>(({ theme, height }) => ({
  bgcolor: theme.palette.gray.light,
  height: theme.typography[height].fontSize,
}));

const RoundedSkeleton = styled(Skeleton)(({ theme }) => ({
  bgcolor: theme.palette.gray.light,
}));

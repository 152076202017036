import { DashboardModalTypes } from './types';

export enum CandidateMatchDashboardActionsEnum {
  SET_ACTIONED_MATCH_IDS = 'SET_ACTIONED_MATCH_IDS',
  SET_TOTAL_PENDING_MATCHES = 'SET_TOTAL_PENDING_MATCHES',
  SET_CURRENT_MATCH_INDEX = 'SET_CURRENT_MATCH_INDEX',
  SET_IS_END_OF_MATCHES = 'SET_IS_END_OF_MATCHES',
  SET_HAS_PENDING_MATCHES = 'SET_HAS_PENDING_MATCHES',
  SET_IS_MODAL_OPEN = 'SET_IS_MODAL_OPEN',
  SET_DISPLAY_TOTAL = 'SET_DISPLAY_TOTAL',
  SET_MATCH_CARD_VACANCIES = 'SET_MATCH_CARD_VACANCIES',
  UPDATE_MATCH_STATUS = 'UPDATE_MATCH_STATUS',
  INITIALIZE_MATCHES = 'INITIALIZE_MATCHES',
}

export const CandidateMatchCardJobPostingsLimit = 5;

export const modalStateKeys = {
  [DashboardModalTypes.REJECT]: 'isRejectMatchModalOpen',
  [DashboardModalTypes.ACCEPT]: 'isAcceptMatchModalOpen',
  [DashboardModalTypes.ALREADY_CONNECTED]: 'isAlreadyConnectedModalOpen',
  [DashboardModalTypes.IN_APP_MESSAGING]: 'isInAppMatchMessagingModalOpen',
} as const;

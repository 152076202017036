import { Box, Container, styled, Theme, useMediaQuery } from '@mui/material';
import {
  ConnectCandidateMatchCardDataTestIds,
  ConnectCandidateMatchDashboardDataTestIds,
} from 'data-testids/ConnectDataTestIds';
import CandidateMatchingAPI from 'features/Connect/api/candidateMatchingAPI';
import { ConnectMatchingAPIViewTypes } from 'features/Connect/api/constants';
import { MatchCardButtonGroup } from 'features/Connect/components/Buttons/MatchCardButtonGroup';
import { CandidateMatchCard } from 'features/Connect/components/MatchCard/CandidateMatchCard';
import { AcceptMatchModal } from 'features/Connect/components/Modals/AcceptMatchModal/AcceptMatchModal';
import { AlreadyConnectedModal } from 'features/Connect/components/Modals/AlreadyConnectedModal/AlreadyConnectedModal';
import { InAppMatchMessagingModal } from 'features/Connect/components/Modals/InAppMatchMessagingModal/InAppMatchMessagingModal';
import { RejectMatchModal } from 'features/Connect/components/Modals/RejectMatchModal/RejectMatchModal';
import { MoreInfoSlider } from 'features/Connect/components/MoreInfoSlider/MoreInfoSlider';
import { useEffect, useReducer } from 'react';
import { MatchActionsResponse, Vacancy } from 'types/connectTypes';
import auth from 'utils/auth';
import { CandidateDashboardViews } from '../../../../CandidatePortal/CandidateDashboardViews/CandidateDashboardViews';
import { ViewState } from '../../../../CandidatePortal/CandidateDashboardViews/utils/constants';
import { useConnectContext } from '../../Context/ConnectContextProvider';
import { CandidateMatchDashboardPaginationHeader } from './CandidateMatchDashboardPaginationHeader';
import {
  candidateMatchDashboardReducer,
  initialCandidateMatchDashboardState,
} from './utils/CandidateMatchDashboardReducer';
import { CandidateMatchDashboardActionsEnum } from './utils/constants';
import { CandidateMatchDashboardActionsInterface, DashboardModalTypes } from './utils/types';
import { CandidateMatchDashboardHeaderSkeleton } from 'features/Connect/components/LoadingSkeletons/MatchDashboardHeaderLoadingSkeleton';
import { CandidateMatchCardSkeleton } from 'features/Connect/components/LoadingSkeletons/MatchCardLoadingSkeleton';

export const CandidateMatchDashboard: React.FC = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const user = auth.getUser();
  const {
    connectContextActions,
    candidateMatches,
    isLoading,
    isMoreInfoSliderOpen,
    hasInitiallyFetchedMatches,
  } = useConnectContext();
  const [state, dispatch] = useReducer(
    candidateMatchDashboardReducer,
    initialCandidateMatchDashboardState
  );

  const {
    currentMatchIndex,
    isEndOfMatches,
    hasPendingMatches,
    totalPages,
    displayTotal,
    modalStates,
    actionedMatchIds,
  } = state;

  const candidateMatchDashboardActions: CandidateMatchDashboardActionsInterface = {
    setActionedMatchIds: (ids: string[]) =>
      dispatch({
        type: CandidateMatchDashboardActionsEnum.SET_ACTIONED_MATCH_IDS,
        payload: ids,
      }),
    setTotalPendingMatches: (total: number) =>
      dispatch({
        type: CandidateMatchDashboardActionsEnum.SET_TOTAL_PENDING_MATCHES,
        payload: total,
      }),
    setCurrentMatchIndex: (index: number) =>
      dispatch({
        type: CandidateMatchDashboardActionsEnum.SET_CURRENT_MATCH_INDEX,
        payload: index,
      }),
    setIsEndOfMatches: (isEnd: boolean) =>
      dispatch({ type: CandidateMatchDashboardActionsEnum.SET_IS_END_OF_MATCHES, payload: isEnd }),
    setHasPendingMatches: (has: boolean) =>
      dispatch({ type: CandidateMatchDashboardActionsEnum.SET_HAS_PENDING_MATCHES, payload: has }),
    setIsModalOpen: (modalName: DashboardModalTypes, isOpen: boolean) =>
      dispatch({
        type: CandidateMatchDashboardActionsEnum.SET_IS_MODAL_OPEN,
        modalName,
        payload: isOpen,
      }),
    setMatchCardVacancies: (vacancies: Vacancy[]) =>
      dispatch({
        type: CandidateMatchDashboardActionsEnum.SET_MATCH_CARD_VACANCIES,
        payload: vacancies,
      }),
    setDisplayTotal: (displayTotal: number) =>
      dispatch({
        type: CandidateMatchDashboardActionsEnum.SET_DISPLAY_TOTAL,
        payload: displayTotal,
      }),
  };

  useEffect(() => {
    const fetchMatches = async () => {
      if (!hasInitiallyFetchedMatches && user) {
        connectContextActions.setIsLoading(true);
        try {
          const matches: MatchActionsResponse = await CandidateMatchingAPI.getMatches(
            ConnectMatchingAPIViewTypes.FULL
          );
          connectContextActions.setCandidateMatches(matches);
          connectContextActions.setHasInitiallyFetchedMatches(true);

          const totalPending = matches.pending.length;
          candidateMatchDashboardActions.setTotalPendingMatches(totalPending);
          candidateMatchDashboardActions.setHasPendingMatches(totalPending > 0);
        } finally {
          connectContextActions.setIsLoading(false);
        }
      }
    };
    fetchMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, hasInitiallyFetchedMatches]);

  const pendingMatches = candidateMatches?.pending || [];
  const currentMatch = pendingMatches[currentMatchIndex];

  const handlePageChange = (newIndex: number) => {
    candidateMatchDashboardActions.setCurrentMatchIndex(newIndex - 1);
  };

  return (
    <MatchDashboardContainer
      data-testid={ConnectCandidateMatchDashboardDataTestIds.DASHBOARD_CONTAINER}
    >
      <MatchDashboardHeaderContainer
        data-testid={ConnectCandidateMatchDashboardDataTestIds.PAGINATION_HEADER_CONTAINER}
      >
        {!hasInitiallyFetchedMatches ? (
          <CandidateMatchDashboardHeaderSkeleton />
        ) : (
          <CandidateMatchDashboardPaginationHeader
            onChange={handlePageChange}
            currentMatch={currentMatchIndex + 1}
            schoolName={currentMatch?.school?.name || ''}
            hasPendingMatches={hasPendingMatches}
            isEndOfMatches={isEndOfMatches}
            totalPages={totalPages}
            displayTotal={displayTotal}
          />
        )}
      </MatchDashboardHeaderContainer>

      {isLoading && !hasInitiallyFetchedMatches ? (
        <MatchDashboardBodyContainer>
          <CandidateMatchCardSkeleton />
        </MatchDashboardBodyContainer>
      ) : (
        <>
          {currentMatch && !isEndOfMatches && (
            <MatchDashboardBodyContainer
              data-testid={ConnectCandidateMatchDashboardDataTestIds.BODY_CONTAINER}
            >
              <CandidateMatchCard
                school={currentMatch.school}
                principal={currentMatch.principal}
                principalMessage={currentMatch?.principal_kickoff_message}
                preferenceMatches={currentMatch.preference_matches}
                candidateMatchDashboardActions={candidateMatchDashboardActions}
                matchCardVacancies={state.matchCardVacancies}
                actionedMatchIds={actionedMatchIds}
              />
            </MatchDashboardBodyContainer>
          )}
        </>
      )}
      {!isLoading && !hasPendingMatches && !isEndOfMatches && (
        <CandidateDashboardViews viewState={ViewState.NO_PENDING_MATCHES} />
      )}
      {isEndOfMatches && <CandidateDashboardViews viewState={ViewState.END_OF_MATCHES} />}
      <RejectMatchModal
        school={currentMatch?.school}
        isOpen={modalStates[DashboardModalTypes.REJECT]}
        onClose={() => {
          candidateMatchDashboardActions.setIsModalOpen(DashboardModalTypes.REJECT, false);
        }}
        onSubmit={async () => {
          if (currentMatch?.school?.nces_id) {
            const newActionedMatchIds = [...actionedMatchIds, currentMatch.school.nces_id];
            candidateMatchDashboardActions.setActionedMatchIds(newActionedMatchIds);

            candidateMatchDashboardActions.setDisplayTotal(displayTotal);

            candidateMatchDashboardActions.setIsModalOpen(DashboardModalTypes.REJECT, false);
          }
        }}
      />
      <AcceptMatchModal
        isOpen={modalStates[DashboardModalTypes.ACCEPT]}
        onClose={() =>
          candidateMatchDashboardActions.setIsModalOpen(DashboardModalTypes.ACCEPT, false)
        }
        openInAppMessagingModal={() =>
          candidateMatchDashboardActions.setIsModalOpen(DashboardModalTypes.IN_APP_MESSAGING, true)
        }
        school={currentMatch?.school}
        onSubmit={(selectedOption) => {
          if (selectedOption !== 'email' && currentMatch?.school?.nces_id) {
            candidateMatchDashboardActions.setActionedMatchIds([
              ...actionedMatchIds,
              currentMatch.school.nces_id,
            ]);
          }
        }}
      />
      <AlreadyConnectedModal
        isOpen={modalStates[DashboardModalTypes.ALREADY_CONNECTED]}
        onClose={() =>
          candidateMatchDashboardActions.setIsModalOpen(
            DashboardModalTypes.ALREADY_CONNECTED,
            false
          )
        }
        onSubmit={async () => {
          if (currentMatch?.school?.nces_id) {
            const newActionedMatchIds = [...actionedMatchIds, currentMatch.school.nces_id];
            candidateMatchDashboardActions.setActionedMatchIds(newActionedMatchIds);

            candidateMatchDashboardActions.setDisplayTotal(displayTotal);

            candidateMatchDashboardActions.setIsModalOpen(
              DashboardModalTypes.ALREADY_CONNECTED,
              false
            );
          }
        }}
        school={currentMatch?.school}
      />
      <InAppMatchMessagingModal
        isOpen={modalStates[DashboardModalTypes.IN_APP_MESSAGING]}
        school={currentMatch?.school}
        principal={currentMatch?.principal}
        principalMessage={currentMatch?.principal_kickoff_message}
        onClose={() => {
          candidateMatchDashboardActions.setIsModalOpen(DashboardModalTypes.ACCEPT, false);
          candidateMatchDashboardActions.setIsModalOpen(
            DashboardModalTypes.IN_APP_MESSAGING,
            false
          );
        }}
        onSubmit={() => {
          if (currentMatch?.school?.nces_id) {
            const newActionedMatchIds = [...actionedMatchIds, currentMatch.school.nces_id];
            candidateMatchDashboardActions.setActionedMatchIds(newActionedMatchIds);

            candidateMatchDashboardActions.setIsModalOpen(DashboardModalTypes.ACCEPT, false);
            candidateMatchDashboardActions.setIsModalOpen(
              DashboardModalTypes.IN_APP_MESSAGING,
              false
            );
          }
        }}
      />
      <MoreInfoSlider
        school={currentMatch?.school}
        isCandidateDashboard={true}
        isOpen={isMoreInfoSliderOpen}
        closeHandler={() => connectContextActions.setIsMoreInfoSliderOpen(false)}
        openHandler={() => connectContextActions.setIsMoreInfoSliderOpen(true)}
      />
      {isMobile && (
        <MatchCardMobileButtonGroupContainer
          data-testid={ConnectCandidateMatchCardDataTestIds.BUTTON_GROUP_CONTAINER}
        >
          <MatchCardButtonGroup
            candidateMatchDashboardActions={candidateMatchDashboardActions}
            school={currentMatch?.school}
            hasMatchBeenActioned={actionedMatchIds?.includes(currentMatch?.school?.nces_id)}
          />
        </MatchCardMobileButtonGroupContainer>
      )}
    </MatchDashboardContainer>
  );
};

const MatchDashboardContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(3),
  height: '100%',
  width: '100%',
  maxWidth: '1040px',
  padding: theme.spacing(3, 0, 8, 0),
}));

const MatchDashboardHeaderContainer = styled(Box)({
  width: '100%',
  padding: '0px',
});

const MatchDashboardBodyContainer = styled(Box)({
  width: '100%',
});

const MatchCardMobileButtonGroupContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  width: '100%',
  padding: theme.spacing(2),
  backgroundColor: 'white',
  boxShadow: '0px -2px 4px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
}));

import { createContext, useContext, useReducer, useRef, useEffect } from 'react';
import { MatchActionsResponse, School } from 'types/connectTypes';
import {
  ConnectContextType,
  ConnectContextActionType,
  ConnectContextStateInterface,
  ConnectContextProviderInterface,
} from 'features/Connect/features/Context/types';
import { ConnectContextActionTypes } from 'features/Connect/utils/connectEnums';

export const connectContextInitialState: ConnectContextStateInterface = {
  contactedSchoolIds: {},
  isAuthenticatedAdminModalOpen: false,
  isExpressInterestExitModalOpen: false,
  isExpressInterestModalOpen: false,
  isLoading: true,
  isMoreInfoSliderOpen: false,
  isReadyToConnectModalOpen: false,
  isUserAuthenticated: false,
  savedSchoolIds: {},
  selectedSchool: null,
  selectedVacancyId: '0',
  candidateMatches: {
    total: 0,
    rejections: [],
    connections: [],
    pending: [],
  },
  hasInitiallyFetchedMatches: false,
};

const connectContextReducer = (
  connectContextState: ConnectContextStateInterface,
  connectContextActions: ConnectContextActionType
): ConnectContextStateInterface => {
  switch (connectContextActions.type) {
    case ConnectContextActionTypes.SET_CANDIDATE_MATCHES:
      return {
        ...connectContextState,
        candidateMatches: connectContextActions.payload,
      };

    case ConnectContextActionTypes.SET_CONTACTED_SCHOOL_IDS:
      return {
        ...connectContextState,
        contactedSchoolIds: {
          ...connectContextState.contactedSchoolIds,
          [connectContextActions.payload]: true,
        },
      };

    case ConnectContextActionTypes.SET_IS_AUTHENTICATED_ADMIN_MODAL_OPEN:
      return {
        ...connectContextState,
        isAuthenticatedAdminModalOpen: connectContextActions.payload,
      };

    case ConnectContextActionTypes.SET_IS_EXPRESS_INTEREST_EXIT_MODAL_OPEN:
      return {
        ...connectContextState,
        isExpressInterestExitModalOpen: connectContextActions.payload,
      };

    case ConnectContextActionTypes.SET_IS_EXPRESS_INTEREST_MODAL_OPEN:
      return {
        ...connectContextState,
        isExpressInterestModalOpen: connectContextActions.payload,
      };

    case ConnectContextActionTypes.SET_IS_LOADING:
      return {
        ...connectContextState,
        isLoading: connectContextActions.payload,
      };

    case ConnectContextActionTypes.SET_IS_MORE_INFO_SLIDER_OPEN:
      return {
        ...connectContextState,
        isMoreInfoSliderOpen: connectContextActions.payload,
      };

    case ConnectContextActionTypes.SET_IS_READY_TO_CONNECT_MODAL_OPEN:
      return {
        ...connectContextState,
        isReadyToConnectModalOpen: connectContextActions.payload,
      };

    case ConnectContextActionTypes.SET_IS_USER_AUTHENTICATED:
      return {
        ...connectContextState,
        isUserAuthenticated: connectContextActions.payload,
      };

    case ConnectContextActionTypes.SET_SELECTED_SCHOOL:
      return {
        ...connectContextState,
        selectedSchool: connectContextActions.payload,
      };

    case ConnectContextActionTypes.SET_SELECTED_VACANCY_ID:
      return {
        ...connectContextState,
        selectedVacancyId: connectContextActions.payload,
      };

    case ConnectContextActionTypes.TOGGLE_SAVED_SCHOOL_IDS: {
      const schoolId = connectContextActions.payload;
      const newSavedSchoolIds = { ...connectContextState.savedSchoolIds };
      if (newSavedSchoolIds[schoolId]) {
        delete newSavedSchoolIds[schoolId];
      } else {
        newSavedSchoolIds[schoolId] = true;
      }
      return {
        ...connectContextState,
        savedSchoolIds: newSavedSchoolIds,
      };
    }

    case ConnectContextActionTypes.SET_HAS_INITIALLY_FETCHED_MATCHES:
      return {
        ...connectContextState,
        hasInitiallyFetchedMatches: connectContextActions.payload,
      };

    default:
      return connectContextState;
  }
};

const ConnectContext = createContext<ConnectContextType | undefined>(undefined);

export const ConnectContextProvider: React.FC<ConnectContextProviderInterface> = ({ children }) => {
  const [connectContextState, dispatch] = useReducer(
    connectContextReducer,
    connectContextInitialState
  );

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const connectContextActions = {
    hasSchoolBeenContacted: (schoolId: string): boolean => {
      return !!connectContextState.contactedSchoolIds[schoolId];
    },
    hasSchoolBeenSaved: (schoolId: string): boolean => {
      return !!connectContextState.savedSchoolIds[schoolId];
    },
    setCandidateMatches: (candidateMatches: MatchActionsResponse) =>
      dispatch({
        type: ConnectContextActionTypes.SET_CANDIDATE_MATCHES,
        payload: candidateMatches,
      }),
    setContactedSchoolIds: (schoolId: string) =>
      dispatch({ type: ConnectContextActionTypes.SET_CONTACTED_SCHOOL_IDS, payload: schoolId }),
    setIsAuthenticatedAdminModalOpen: (isOpen: boolean) => {
      if (isMounted.current) {
        dispatch({
          type: ConnectContextActionTypes.SET_IS_AUTHENTICATED_ADMIN_MODAL_OPEN,
          payload: isOpen,
        });
      }
    },
    setIsExpressInterestExitModalOpen: (isOpen: boolean) =>
      dispatch({
        type: ConnectContextActionTypes.SET_IS_EXPRESS_INTEREST_EXIT_MODAL_OPEN,
        payload: isOpen,
      }),
    setIsExpressInterestModalOpen: (isOpen: boolean) =>
      dispatch({
        type: ConnectContextActionTypes.SET_IS_EXPRESS_INTEREST_MODAL_OPEN,
        payload: isOpen,
      }),
    setIsLoading: (isLoading: boolean) => {
      if (isMounted.current) {
        dispatch({
          type: ConnectContextActionTypes.SET_IS_LOADING,
          payload: isLoading,
        });
      }
    },
    setIsMoreInfoSliderOpen: (isOpen: boolean) =>
      dispatch({
        type: ConnectContextActionTypes.SET_IS_MORE_INFO_SLIDER_OPEN,
        payload: isOpen,
      }),
    setIsReadyToConnectModalOpen: (isOpen: boolean) =>
      dispatch({
        type: ConnectContextActionTypes.SET_IS_READY_TO_CONNECT_MODAL_OPEN,
        payload: isOpen,
      }),
    setIsUserAuthenticated: (isAuthenticated: boolean) =>
      dispatch({
        type: ConnectContextActionTypes.SET_IS_USER_AUTHENTICATED,
        payload: isAuthenticated,
      }),
    setSelectedSchool: (school: School) =>
      dispatch({
        type: ConnectContextActionTypes.SET_SELECTED_SCHOOL,
        payload: school,
      }),
    setSelectedVacancyId: (selectedVacancyId: string) =>
      dispatch({
        type: ConnectContextActionTypes.SET_SELECTED_VACANCY_ID,
        payload: selectedVacancyId,
      }),
    toggleSavedSchoolIds: (schoolId: string) =>
      dispatch({ type: ConnectContextActionTypes.TOGGLE_SAVED_SCHOOL_IDS, payload: schoolId }),
    setHasInitiallyFetchedMatches: (hasFetched: boolean) =>
      dispatch({
        type: ConnectContextActionTypes.SET_HAS_INITIALLY_FETCHED_MATCHES,
        payload: hasFetched,
      }),
  };

  return (
    <ConnectContext.Provider value={{ ...connectContextState, connectContextActions }}>
      {children}
    </ConnectContext.Provider>
  );
};

export const useConnectContext = () => {
  const context = useContext(ConnectContext);
  if (context === undefined) {
    throw new Error('useConnectContext must be used within an ConnectContextProvider');
  }
  return context;
};
